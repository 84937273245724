const menu = {
    list() {
        return [
            {"backMenu":[{"child":[{"buttons":["新增","查看","修改","删除"],"menu":"公告信息","menuJump":"列表","tableName":"gonggaoxinxi"}],"menu":"公告信息管理"},
                    {"child":[{"buttons":["新增","查看","修改","删除"],"menu":"部门","menuJump":"列表","tableName":"bumen"}],"menu":"部门管理"},
                    {"child":[{"buttons":["新增","查看","修改","删除"],"menu":"岗位","menuJump":"列表","tableName":"gangwei"}],"menu":"岗位管理"},
                    {"child":[{"buttons":["新增","查看","修改","删除","绩效"],"menu":"员工","menuJump":"列表","tableName":"yuangong"}],"menu":"员工管理"},
                    {"child":[
                        {"buttons":["新增","查看","修改","删除"],"menu":"业绩管理","menuJump":"列表","tableName":"yeji"},
                            {"buttons":["新增","查看","修改","删除"],"menu":"业绩统计","menuJump":"列表","tableName":"yejitongji"}
                        ],"menu":"业绩管理"},
                    // {"child":[{"buttons":["新增","查看","修改","删除","打印","导出"],"menu":"绩效指标","menuJump":"列表","tableName":"jixiaozhibiao"}],"menu":"绩效指标管理"},
                    // {"child":[{"buttons":["查看","修改","删除","打印","导出","报表"],"menu":"绩效考核","menuJump":"列表","tableName":"jixiaokaohe"}],"menu":"绩效考核管理"}
                ],"frontMenu":[],"hasBackLogin":"是","hasBackRegister":"否","hasFrontLogin":"否","hasFrontRegister":"否","roleName":"管理员","tableName":"users"},{"backMenu":[{"child":[{"buttons":["查看"],"menu":"公告信息","menuJump":"列表","tableName":"gonggaoxinxi"}],"menu":"公告信息管理"}],"frontMenu":[],"hasBackLogin":"是","hasBackRegister":"否","hasFrontLogin":"否","hasFrontRegister":"否","roleName":"员工","tableName":"yuangong"}]
    }
}
export default menu;
