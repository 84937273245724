<template>
  <el-dialog
      title="比例设置"
      :visible.sync="ratioVisible"
      width="30%"
      :before-close="onCancel">
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
    >
      <el-row>
        <el-form-item class="input" label="第一代" prop="level1">
          <el-input v-model="ruleForm.ratiolevel1" placeholder="请输入比例" clearable></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item class="input" label="第二代" prop="level2">
          <el-input v-model="ruleForm.ratiolevel2" placeholder="请输入比例" clearable></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item class="input" label="第三代" prop="level3">
          <el-input v-model="ruleForm.ratiolevel3" placeholder="请输入比例" clearable></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onCancel()">取 消</el-button>
      <el-button type="primary" @click="onOK()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    ratioVisible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: {
        ratiolevel1: [
          {required: true, message: '请输入第一代比例', trigger: 'blur'},
        ],
        ratiolevel2: [
          {required: true, message: '请输入第二代比例', trigger: 'blur'},
        ],
        ratiolevel3: [
          {required: true, message: '请输入第三代比例', trigger: 'blur'},
        ],
      },
      ruleForm: {
        ratiolevel1: 0,
        ratiolevel2: 0,
        ratiolevel3: 0
      }
    }
  },
  created() {
    this.getDatas();
  },
  methods: {
    submit () {
      this.$refs["ruleForm"].validate(valid => {
        console.log(this.ruleForm)
        if (valid) {
          this.$http({
            url: `config/updates`,
            method: "post",
            data: JSON.stringify(this.ruleForm)
          }).then(({data}) => {
            if (data.code === 0) {
              this.$message.success('修改成功');
              this.onCancel();
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    getDatas () {
      this.$http({
        url: `config/queryByNames`,
        method: "get",
        params: {
          names: 'ratiolevel1,ratiolevel2,ratiolevel3'
        }
      }).then(({ data }) => {
        const ruleForm = {};
        data.data.forEach(item => ruleForm[item.name] = item.value);
        this.ruleForm = ruleForm;
      });
    },
    onCancel () {
      this.$emit('onCancel', false)
    },
    onOK () {
      this.submit();
    }
  }
}
</script>
<style></style>