<template>
  <el-dialog
      title="删除审核"
      :visible.sync="auditVisible"
      width="30%">
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
    >
      <el-row>
        <el-form-item class="input" label="审核状态" prop="auditStatus">
          <el-radio v-model="ruleForm.auditStatus" label="2">通过</el-radio>
          <el-radio v-model="ruleForm.auditStatus" label="3">驳回</el-radio>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item class="input" label="审核原因" prop="auditRemark">
          <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="ruleForm.auditRemark"></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default
{
  data () {
    return {
      rules: {
        auditStatus: [
          {required: true, message: '请选择审核状态', trigger: 'blur'},
        ],
        auditRemark: [
          {required: true, message: '请输入审核原因', trigger: 'blur'},
        ]
      },
      ruleForm: {
        auditStatus: "2",
        auditRemark: ''
      }
    }
  },
  props: ['auditVisible', 'auditId'],
  methods: {
    submit () {
      const params = { ...this.ruleForm };
      params.auditId = this.auditId;
      this.$refs["ruleForm"].validate(valid => {
        if (valid) {
          this.$http({
            url: `zijinliushui/deleteAudit`,
            method: "post",
            data: JSON.stringify(params)
          }).then(({data}) => {
            if (data.code === 0) {
              this.$message.success('审核成功');
              this.$emit('onOk');
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    onCancel() {
      this.$emit('onCancel');
    }
  }
}
</script>