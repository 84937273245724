const base = {
    get() {
        const env = process.env.NODE_ENV;
        const params = {
            name: "springboot57n6g"
        };
        if (env === 'development') {
            params.url = "http://127.0.0.1:9091/springboot57n6g/";
            params.indexUrl = 'http://127.0.0.1:8081/springboot57n6g/front/index.html';
            params.fileUrl = 'http://127.0.0.1:80/springboot57n6g/';
        } else {
            params.url = "https://wdq.wdq99.com/webapi/springboot57n6g/";
            params.indexUrl = 'http://120.26.226.67:5001/springboot57n6g/front/index.html';
            params.fileUrl = 'https://120.26.226.67:5003/springboot57n6g/';
        }
        return params;
    },
    getProjectName(){
        return {
            projectName: "员工业绩管理系统"
        } 
    }
}
export default base
